import "../scss/app.scss";
import Vue from "vue";
import { gsap } from "gsap";
import ScrollTrigger from "gsap/ScrollTrigger";
import { left, right } from "@popperjs/core";
gsap.registerPlugin(ScrollTrigger);
import "bootstrap";

const app = new Vue({
  el: "#app",
  data: {
    toggleMenu: false,
    loaded: false,
  },
  mounted() {
    this.InView();
    this.InViewElementWave();
    this.loaded = true;
  },
  methods: {
    InView() {
      const trigger = document.querySelectorAll("[data-trigger]");
      trigger.forEach(function(userItem) {
        ScrollTrigger.create({
          trigger: userItem,
          start: "top bottom-=50px",
          end: "bottom top+=50px",
          toggleClass: { targets: userItem, className: "InView" },
        });
      });
    },
    InViewElementWave() {
      gsap.set(".InViewElementWave", {
        y: 30,
        opacity: 0,
      });
      gsap.to(".InViewElementWave", {
        delay: 1,
        duration: 1.5,
        y: 0,
        opacity: 1,
        ease: "power2.out",
        // 複数要素を扱うプロパティ
        stagger: {
          from: "start", //左側から
          amount: 0.8, // 0.8秒おきに
        },
        scrollTrigger: {
          trigger: ".InViewElementWave",
          start: "top bottom-=50px",
          end: "bottom top+=50px",
          scrub: true,
          // markers: true, // マーカー表示
        },
      });
    },
  },
});
